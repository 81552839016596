export default {
  baseURL: "https://cifeng.cc/api",
  // baseURL: "http://localhost:8081",
  imBaseURL: "",
  webURL: "https://cifeng.cc",
  live2d_path: "https://cdn.jsdelivr.net/gh/stevenjoezhang/live2d-widget@latest/",
  cdnPath: "https://cdn.jsdelivr.net/gh/fghrsh/live2d_api/",
  waifuPath: "/webInfo/getWaifuJson",
  hitokoto: "https://v1.hitokoto.cn",
  shehui: "https://api.oick.cn/yulu/api.php",
  tocbot: "https://cdnjs.cloudflare.com/ajax/libs/tocbot/4.11.1/tocbot.min.js",
  jinrishici: "https://v1.jinrishici.com/all.json",
  random_image: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg?",
  //前后端定义的密钥，AES使用16位
  cryptojs_key: "aoligeinizhenniu",
  // 七牛云上传域名
  qiniuUrl: "https://upload-z1.qiniup.com",
  // 七牛云下载域名
  qiniuDownload: "https://oss.cifeng.cc/",

  favoriteVideo: "$$$$自己找一个视频链接作为百宝箱的封面",

  loveWeiYan: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  loveMessage: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  lovePhoto: "https://s1.ax1x.com/2022/12/04/zsKh5V.jpg",
  loveLike: "https://oss.cifeng.cc/webAvatar/11695459865222235.svg",
  loveSortId: 1,
  loveLabelId: 1,

  friendBG: "https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
  friendLetterTop: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/before.png",
  friendLetterBottom: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/after.png",
  friendLetterBiLi: "https://cdn.cbd.int/hexo-butterfly-envelope/lib/line.png",
  friendLetterMiddle: "https://cdn.cbd.int//hexo-butterfly-envelope/lib/violet.jpg",

  before_color_list: ["#ff4b2b", "#EF794F", "#67C23A", "orange", "rgb(131, 123, 199)", "#23d5ab"],

  tree_hole_color: ["#ee7752", "#e73c7e", "#23a6d5", "#23d5ab", "rgb(131, 123, 199)", "#23d5ab"],

  two_poem_image: ["https://s1.ax1x.com/2022/12/04/zsKgDs.jpg",
    "https://s1.ax1x.com/2022/12/04/zsKh5V.jpg"],

  before_color_1: "black",
  after_color_1: "linear-gradient(45deg, #f43f3b, #ec008c)",

  before_color_2: "rgb(131, 123, 199)",
  after_color_2: "linear-gradient(45deg, #f43f3b, #ec008c)",

  sortColor: ["linear-gradient(to right, #358bff, #15c6ff)",
    "linear-gradient(to right, #18e7ae, #1eebeb)",
    "linear-gradient(to right, #ff6655, #ffbf37)",
    "linear-gradient(120deg, rgba(255, 39, 232, 1) 0%, rgba(255, 128, 0, 1) 100%)",
    "linear-gradient(120deg, rgba(91, 39, 255, 1) 0%, rgba(0, 212, 255, 1) 100%)"
  ],

  pageColor: "#ee7752",
  commentPageColor: "#23d5ab",
  userId: 1,
  source: 0,

  emojiList: ['衰', '鄙视', '再见', '捂嘴', '摸鱼', '奋斗', '白眼', '可怜', '皱眉', '鼓掌', '烦恼', '吐舌', '挖鼻', '委屈', '滑稽', '啊这', '生气', '害羞', '晕', '好色', '流泪', '吐血', '微笑', '酷', '坏笑', '吓', '大兵', '哭笑', '困', '呲牙']
}
